import { gql } from '@/__generated__';
import { withFragment } from '@liquorice/gql-utils';
import { CardBase, CardIconImage, CardInfo } from '..';

export const SERVICES_CARD_FRAGMENT = gql(`
  fragment ServiceCard on page_Entry {
    __typename
    id
    title
    summary
    url
    uri
    iconAsset {
      ...ImageAsset
    }
    serviceCategoryMultiple {
      ...Category
    }
  }
`);

export const ServicesCard = withFragment(SERVICES_CARD_FRAGMENT, (service) => {
  return (
    <CardBase>
      <CardIconImage icon={service?.iconAsset} />
      <CardInfo
        title={service?.title || ''}
        description={service?.summary || ''}
        linkHref={service?.uri || '#'}
      />
    </CardBase>
  );
});
