import { VariantProps, cva } from 'class-variance-authority';
import { ReactNode } from 'react';

type TwoColumnProps = VariantProps<typeof TwoColumnStyles> & {
  leftContent: ReactNode;
  rightContent: ReactNode;
};

const TwoColumnStyles = cva(
  'flex flex-col gap-8 lg:items-center mb-6', // Base styles
  {
    variants: {
      reverse: {
        true: 'lg:flex-row-reverse',
        false: 'lg:flex-row',
      },
    },
    defaultVariants: {
      reverse: false, // Default size if none is provided
    },
  }
);

export const TwoColumn = ({ leftContent, rightContent, reverse }: TwoColumnProps) => {
  return (
    <div className={TwoColumnStyles({ reverse })}>
      <div className="lg:w-1/2">{leftContent}</div>
      <div className="lg:w-1/2">{rightContent}</div>
    </div>
  );
};
