'use client';

import { Button } from '@/components/Atoms/Button';
import { RightArrow } from '@/components/Atoms/Icons';
import LeftArrow from '@/components/Atoms/Icons/LeftArrow';
import ImageAsset, { IMAGE_ASSET_FRAGMENT } from '@/components/Atoms/ImageAsset';
import { withFragmentArray } from '@liquorice/gql-utils';
import { useEffect, useRef, useState } from 'react';

export interface ScrollingImageBlockProps {
  controls?: 'overlay' | 'bottom';
}

export const ScrollingImageBlock = withFragmentArray(
  IMAGE_ASSET_FRAGMENT,
  (images, { controls = 'bottom' }: ScrollingImageBlockProps) => {
    const slideshowRef = useRef<HTMLUListElement>(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToNextSlide = () => {
      if (slideshowRef.current) {
        const nextIndex = (currentIndex + 1) % images.length;
        slideshowRef.current.scrollLeft = slideshowRef.current.clientWidth * nextIndex;
      }
    };

    const goToPreviousSlide = () => {
      if (slideshowRef.current) {
        const previousIndex = (currentIndex - 1 + images.length) % images.length;
        slideshowRef.current.scrollLeft = slideshowRef.current.clientWidth * previousIndex;
      }
    };

    // add an event listener to the slideshowRef to update the currentIndex when the user scrolls
    useEffect(() => {
      if (slideshowRef.current != undefined) {
        const currentRef = slideshowRef.current;
        const handleScroll = () => {
          const index = Math.round(currentRef.scrollLeft / currentRef.clientWidth);
          setCurrentIndex(index);
        };
        currentRef.addEventListener('scroll', handleScroll);
        return () => {
          currentRef.removeEventListener('scroll', handleScroll);
        };
      }
    });

    return (
      <div className="relative">
        <ul
          ref={slideshowRef}
          className="no-scrollbar flex w-full snap-x snap-mandatory overflow-x-scroll scroll-smooth rounded-3xl md:rounded-[32px] lg:rounded-5xl">
          {images.map((image, index) => (
            <li key={index} className={`w-full flex-shrink-0 snap-center snap-always`}>
              <ImageAsset data={image} />
            </li>
          ))}
        </ul>
        {controls === 'overlay' && (
          <>
            <div className="absolute left-4 top-1/2 -translate-y-1/2 transform">
              <Button
                aria-label="Previous image"
                element="button"
                size="circle"
                color="white"
                onClick={goToPreviousSlide}>
                <LeftArrow className="stroke-green-600" />
              </Button>
            </div>
            <div className="absolute right-4 top-1/2 -translate-y-1/2 transform">
              <Button
                aria-label="Next image"
                element="button"
                size="circle"
                color="white"
                onClick={goToNextSlide}>
                <RightArrow className="stroke-green-600" />
              </Button>
            </div>
          </>
        )}
        {controls === 'bottom' && (
          <div className="mt-5 flex flex-col gap-4 lg:flex-row lg:justify-between">
            <p>{images[currentIndex].filename || ''}</p>
            <div className="flex items-center gap-4 text-green-600">
              <Button
                aria-label="Previous image"
                element="button"
                size="circleSmall"
                color="whiteOutlined"
                onClick={goToPreviousSlide}>
                <LeftArrow className="stroke-green-600" />
              </Button>
              <h6>
                {currentIndex + 1}/{images.length}
              </h6>
              <Button
                aria-label="Next image"
                element="button"
                size="circleSmall"
                color="whiteOutlined"
                onClick={goToNextSlide}>
                <RightArrow className="stroke-green-600" />
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  },
);
