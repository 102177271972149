import dayjs from 'dayjs';
import { LinkArrow } from '../Atoms/LinkArrow/LinkArrow';
import { Button } from '../Atoms/Button';
import React from 'react';
import { normalizeLinkHref } from '../../lib/parsers/normalizeLinkHref';

interface CardInfoProps {
  title: string;
  description: string;
  linkHref: string;
  linkText?: string;
  date?: string | null;
  tags?: {
    name: string;
    url: string;
  }[];
  meta?: React.ReactNode;
}

const cardDate = (date: string | Date) => dayjs(date).format('DD MMMM YYYY');

const CardInfo = ({
  title,
  description,
  linkHref,
  linkText = 'Learn more',
  date = null,
  tags = [],
  meta = null,
}: CardInfoProps) => {
  // check if url is relative or absolute
  const linkUrl = normalizeLinkHref(linkHref);
  return (
    <div className="flex h-auto grow flex-col gap-4 p-6 md:p-8">
      {date && <p className="text-base">{cardDate(date)}</p>}
      <h5 className="">{title}</h5>
      {tags && tags.length > 0 && (
        <div className="flex gap-2">
          {tags.map((tag) => (
            <Button key={tag.name} size="xsmall" color="lightBlue" href={tag.url}>
              {tag.name}
            </Button>
          ))}
        </div>
      )}
      {meta && <div>{meta}</div>}
      <p className="grow text-base">{description}</p>
      <LinkArrow linkHref={linkUrl} cover>
        {linkText}
      </LinkArrow>
    </div>
  );
};

export default CardInfo;
