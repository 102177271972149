import { gql } from '@/__generated__';
import { CardBase, CardImage, CardInfo } from '..';
import { withFragment } from '@liquorice/gql-utils';

export const EVENT_CARD_FRAGMENT = gql(`
  fragment EventCard on page_Entry {
    __typename
    id
    title
    url
    summary
    featureImage {
      ...ImageAsset
    }
    startDate
    sectionHandle
  }
`);

const EventCard = withFragment(EVENT_CARD_FRAGMENT, (data) => {
  return (
    <CardBase>
      <CardImage image={data?.featureImage[0]} />
      <CardInfo
        title={data?.title || ''}
        date={data?.startDate}
        description={data?.summary || ''}
        linkHref={data?.url || '#'}
      />
    </CardBase>
  );
});

export default EventCard;
