import { gql } from '@/__generated__';
import { withFragment } from '@liquorice/gql-utils';
import { CardBase, CardImage, CardInfo } from '../';

export const ARTICLE_CARD_FRAGMENT = gql(`
  fragment ArticleCard on page_Entry {
    __typename
    id
    title
    uri
    summary
    featureImage {
      ...ImageAsset
    }
    articleCategoryMultiple {
      ...Category
    }
    postDate
    sectionHandle
  }
`);

const ArticleCard = withFragment(ARTICLE_CARD_FRAGMENT, (article) => {
  return (
    <CardBase>
      <CardImage image={article?.featureImage[0]} />
      <CardInfo
        title={article?.title || ''}
        date={article?.postDate}
        description={article?.summary || ''}
        linkHref={article?.uri || '#'}
        tags={[
          {
            name: 'Employment',
            url: '#',
          },
          {
            name: 'NDIS',
            url: '#',
          },
        ]}
      />
    </CardBase>
  );
});

export default ArticleCard;
