'use client';

import { gql } from '@/__generated__';
import { Responsive3ColGrid } from '@/components/Atoms/Layouts';
import ArticleCard from '@/components/Cards/ArticleCard/ArticleCard';
import { useQuery } from '@apollo/client';

const LATEST_NEWS_ENTRIES_QUERY = gql(`
  query LatestArticles($limit: Int = 4) {
    articleEntries(limit: $limit) {
      ...ArticleCard
    }
  }`);

interface LatestArticlesProps {
  limit?: number;
}

const LatestArticles = ({ limit = 3 }: LatestArticlesProps) => {
  const { data, loading, error } = useQuery(LATEST_NEWS_ENTRIES_QUERY, {
    variables: {
      limit: limit, // custom limit if you want
    },
  });

  if (loading) return <p>Loading...</p>;

  if (error) return <p>Error: {error.message}</p>;

  const entries = data?.articleEntries;

  return (
    <Responsive3ColGrid>
      {entries?.map((entry, index) => <ArticleCard key={index} data={entry} />)}
    </Responsive3ColGrid>
  );
};

export default LatestArticles;
