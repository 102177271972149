import { gql } from '@/__generated__';
import { AccordionMulti } from '@/components/Accordion';
import { FluidContainer } from '@/components/Atoms/Layouts';
import createBlock, { BlockMeta } from '@/components/Blocks/createBlock';

export const ACCORDION_ITEM_FRAGMENT = gql(`
  fragment AccordionItem on accordionItem_Entry {
    id
    title
    htmlContentDefault
  }
`);

const ACCORDION_BLOCK_FRAGMENT = gql(`
  fragment AccordionBlock on blocks_blockAccordion_BlockType {
    id
    accordionItems {
      ...AccordionItem
    }
  }
`);

export interface AccordionBlockProps extends BlockMeta {}

const AccordionBlock = createBlock(ACCORDION_BLOCK_FRAGMENT, (data, props: AccordionBlockProps) => {
  const { accordionItems } = data;

  return (
    <FluidContainer width="article">
      <a id={props.anchor} />
      <AccordionMulti data={accordionItems} />
    </FluidContainer>
  );
});

export default AccordionBlock;
